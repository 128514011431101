<template>
  <v-main align="center">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-card class="elevation-12">
            <GatewayToolbar :title="$t('gateway.toolbarTitle')" />
            <router-view></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import GatewayToolbar from "@/components/gateway/GatewayToolbar";
export default {
  name: "GatewayView",
  components: {
    GatewayToolbar,
  },
};
</script>
